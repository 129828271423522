<template>
    <div class="formgrid grid p-fluid mt-2">
        <div class="field col-12 md:col-2 lg:col-2">
            <label>Código</label>
            <InputText type="text" v-model="codigo" maxlength="100" />
        </div>
        <div class="field col-12 md:col-2 lg:col-2">
            <label>Código Interno</label>
            <InputNumber v-model="codigoInterno" :useGrouping="false" />
        </div>
        <div class="field col-12 md:col-2 lg:col-2">
            <label>Código Externo</label>
            <InputText type="text" v-model="codigoExterno" maxlength="100" />
        </div>
        <div class="field col-12 md:col-4 lg:col-3">
            <label>Nome</label>
            <InputText type="text" v-model="nome" maxlength="500" />
        </div>
        <div class="field col-12 md:col-4 lg:col-3">
            <label>Descrição</label>
            <InputText type="text" v-model="descricao" maxlength="1000" />
        </div>
        <div class="field col-12 md:col-4 lg:col-3">
            <label>Data Início</label>
            <InputText type="date" v-model="dataInicio" maxlength="1000" />
        </div>
        <div class="field col-12 md:col-4 lg:col-3">
            <label>Data Fim</label>
            <InputText type="date" v-model="dataFim" maxlength="1000" />
        </div>
    </div>
    <div class="flex flex-column md:flex-row justify-content-between">
        <div>
            <Button label="Pesquisar" icon="pi pi-search" @click="pesquisar()" />
            <Button label="Limpar" icon="pi pi-refresh" @click="limpar()" class="ml-2 p-button-secondary" />
        </div>
        <div>
            <btn-inserir :nivel="0" :evento="null"></btn-inserir>
        </div>
    </div>
</template>

<script>
import BtnInserir from './BtnInserir';

export default {
    components: {
        BtnInserir,
    },

    emits: ['pesquisar', 'limpar'],

    data() {
        return {
            codigo: null,
            codigoInterno: null,
            codigoExterno: null,
            nome: null,
            descricao: null,
            dataInicio: null,
            dataFim: null,
        };
    },

    methods: {
        pesquisar() {
            this.$emit('pesquisar', {
                codigo: this.codigo,
                codigoInterno: this.codigoInterno,
                codigoExterno: this.codigoExterno,
                nome: this.nome,
                descricao: this.descricao,
                dataInicio: this.dataInicio ? this.$moment(this.dataInicio).format('YYYY-MM-DDTHH:mm:ss') : '',
                dataFim: this.dataFim ? this.$moment(this.dataFim).format('YYYY-MM-DDTHH:mm:ss') : '',
            });
        },

        limpar() {
            this.codigo = null;
            this.codigoInterno = null;
            this.codigoExterno = null;
            this.nome = null;
            this.descricao = null;
            this.dataInicio = null;
            this.dataFim = null;
            this.$emit('limpar');
        },
    },
};
</script>
