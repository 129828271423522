<template>
    <painel titulo="Eventos - Filtros" icone="pi pi-calendar" :mostrarRefresh="false" v-show="mostrarListagem">
        <filtros @pesquisar="pesquisar($event)" @limpar="limpar()"></filtros>
    </painel>
    <painel titulo="Eventos" icone="pi pi-calendar" :refreshFunction="atualizar" v-show="mostrarListagem" v-if="eventos">
        <listagem-base @obterEventoSelecionados="obterEventoSelecionados($event)" :eventos="eventos" :nivel="nivel"></listagem-base>
    </painel>
    <router-view></router-view>
</template>

<script>
import ListagemBase from './ListagemBase';
import EventosServices from './services';
import Filtros from './Filtros.vue';

export default {
    components: {
        ListagemBase,
        Filtros,
    },

    data() {
        return {
            eventos: null,
            nivel: 0,
            filtros: null,
        };
    },

    methods: {
        pesquisar(filtros) {
            this.filtros = filtros;
            this.obterEventos();
        },

        obterEventoSelecionados(eventosSelecionados) {
            this.listagemEventosQrCode = eventosSelecionados.map((evento) => evento.eventoId);
        },

        limpar() {
            this.filtros = null;
            this.eventos = null;
        },

        obterEventos() {
            this.$store.dispatch('addRequest');
            EventosServices.obterTodos(this.filtros).then((response) => {
                if (response?.success) {
                    this.eventos = response.data;
                } else {
                    this.eventos = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar() {
            this.obterEventos();
        },
    },

    computed: {
        mostrarListagem() {
            return this.$route.name == 'Checkins_Eventos';
        },
    },

    watch: {
        $route(to) {
            if (to.name === 'Checkins_Eventos') {
                if (this.$store.getters.atualizar) {
                    this.obterEventos();
                    this.$store.dispatch('setAtualizar', false);
                }
            }
        },
    },
};
</script>
